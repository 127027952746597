.container {
  margin-bottom: 3rem;
}

.container > h1 {
  margin-top: 2rem;
  text-align: center;
}

.card_container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
  justify-items: center;
  margin-block: 1rem;
}

.outer_container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.blog_header {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  padding: 0 1rem;
  align-items: center;
  gap: 1rem;
}

.blog_header > h1 {
  font-size: 30px;
}

.pagination {
  display: flex;
  justify-content: flex-end;
}

.no_blog {
  text-align: center;
  height: 300px;
}

/* -- Mobile landscape */
@media (min-width: 480px) {
}

/* -- Tablet portrait */
@media (min-width: 768px) {
  .card_container {
    grid-template-columns: repeat(2, min-content);
    gap: 1.5rem;
  }
}

/* -- Tablet landscape */
@media (min-width: 992px) {
}

/* -- Laptop (HD) */
@media (min-width: 1200px) {
  .card_container {
    grid-template-columns: repeat(3, min-content);
    gap: 1rem;
  }

  .custom_center {
    margin: 0 !important;
  }
}

/* -- Laptop (HD) */
@media (min-width: 1600px) {
}

/* -- FullHD */
@media (min-width: 1921px) {
}
