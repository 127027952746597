.card {
  position: relative;
  box-sizing: border-box;
  background-color: #f8fafc;
  border-radius: 0.75rem;
  max-width: 300px;
}

.card > div:first-child {
  width: 298px;
}

.content {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 1rem;
}

.new_status {
  position: absolute;
  top: -0.25rem;
  right: -0.25rem;
}

.content > div:first-child {
  margin-bottom: auto;
}

.content h3 {
  margin-top: unset;
  margin-bottom: 0.75rem;
  max-height: 6rem;
  overflow: hidden;
}

@supports (-webkit-line-clamp: 2) {
  .content h3 {
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }
}

.card_footer{
  text-align: center;
}

.card_content{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 190px;
  padding: 0 0 1rem 0;
}

.lang_icon{
  position: absolute;
  bottom: 0.6rem;
  right: 0.6rem;
  z-index:  1;
}

.img_con{
  position: relative;
}

/* -- Tablet landscape */
@media (min-width: 380px) {
  .card {
    max-width: 343px;
    height: 400px;
  }

  .card > div:first-child {
    width: unset;
  }
}

.button{
  width: 91%;
  margin: 0 0.5rem;
}
