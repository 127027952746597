@import url(https://fonts.googleapis.com/css?family=Raleway);
@import url(https://fonts.googleapis.com/css?family=Lato);
/** @format */
body {
    margin: 0;
    font-family: raleway, lato, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
/* width */
::-webkit-scrollbar {
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}
html {
    scroll-behavior: smooth;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

.react-pdf__Page__textContent {
    display: none !important;
}
.react-pdf__Page__annotations {
    display: none !important;
}

.Center_center__182zs {
  padding-inline: 1.5rem;
}

@media (min-width: 1250px) {
  .Center_center__182zs {
    padding-inline: calc(50% - 34.25rem);
    margin: 0 -5rem;
  }
}

.LabelledIconWidget_labelled_widget__3uWNl {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.LabelledIconWidget_labelled_widget__3uWNl > div:first-child {
  display: flex;
  padding: 1rem;
  margin-right: 1rem;
  border-radius: 1rem; 
  width: -webkit-max-content; 
  width: max-content;
  height: -webkit-max-content;
  height: max-content;
  background-color: #000000;
  padding: 1.5rem;
  margin: 0 auto;
}

.LabelledIconWidget_labelled_widget__3uWNl > div:first-child > svg {
  margin: auto;
  width: 2.25rem;
  height: 2.25rem;
}

.BlogSubpage_container__2c_wd {
  margin: 2rem 0;
}

.BlogSubpage_img_con__O_O48 {
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px,
    rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
  position: relative;
  margin-bottom: 32px;
  background-size: cover;
  background-repeat: no-repeat;
  height: 200px;
  width: 100%;
  background-position: center center;
}

.BlogSubpage_grid_container__ySTKL img {
  max-width: 100%;
}

.BlogSubpage_title__2_El4 {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
}

.BlogSubpage_blog_content__3TgW0 img {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

/* -- Mobile landscape */
@media (min-width: 480px) {
  .BlogSubpage_img_con__O_O48 {
    height: 300px;
    text-align: center;
  }
}

/* -- Tablet portrait */
@media (min-width: 768px) {
  .BlogSubpage_img_con__O_O48 {
    height: 400px;
  }
}

/* -- Tablet landscape */
@media (min-width: 992px) {
  .BlogSubpage_grid_container__ySTKL {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-gap: 1.5rem;
    gap: 1.5rem;
    margin: 1rem 0;
  }
}

/* -- Laptop (HD) */
@media (min-width: 1200px) {
  .BlogSubpage_custom_center__9N_BB {
    margin: 0 !important;
  }
}

/* -- Laptop (HD) */
@media (min-width: 1600px) {
}

/* -- FullHD */
@media (min-width: 1921px) {
}

.BlogHeader_header_container__11dA0{
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.BlogHeader_header_end__2UG2J{
  display: flex;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
}
  
/* -- Mobile landscape */
@media (min-width: 480px) {
}

/* -- Tablet portrait */
@media (min-width: 768px) {
}

/* -- Tablet landscape */
@media (min-width: 992px) {
}

/* -- Laptop (HD) */
@media (min-width: 1200px) {
}

/* -- Laptop (HD) */
@media (min-width: 1600px) {

}

/* -- FullHD */
@media (min-width: 1921px) {
}
  
.BlogSidebar_sidebar_container__6qxi_ {
  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  box-shadow: none;
  padding: 16px;
  background-color: #d9d9d9;
  max-height: 200px;
  position: -webkit-sticky;
  position: sticky;
  top: 5rem;
}

.BlogSidebar_sidebar_top__jgfVE {
  padding: 0 1rem;
}

.BlogSidebar_link_container__2BtR1 {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  grid-gap: 1rem;
  gap: 1rem;
}

.BlogSidebar_link_container__2BtR1 > a {
  max-height: 2.5em;
  overflow: hidden;
  text-overflow: ellipsis;
}

.BlogSidebar_progress__1kJHd {
  padding: 3rem;
  display: table !important;
  margin: 0 auto;
}

/* -- Mobile landscape */
@media (min-width: 480px) {
}

/* -- Tablet portrait */
@media (min-width: 768px) {
}

/* -- Tablet landscape */
@media (min-width: 992px) {
}

/* -- Laptop (HD) */
@media (min-width: 1200px) {
}

/* -- Laptop (HD) */
@media (min-width: 1600px) {
}

/* -- FullHD */
@media (min-width: 1921px) {
}

.Blog_container__2YU06 {
  margin-bottom: 3rem;
}

.Blog_container__2YU06 > h1 {
  margin-top: 2rem;
  text-align: center;
}

.Blog_card_container__1rt7v {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
  gap: 1rem;
  justify-items: center;
  margin-block: 1rem;
}

.Blog_outer_container__16iso {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Blog_blog_header__PLi8b {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  padding: 0 1rem;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
}

.Blog_blog_header__PLi8b > h1 {
  font-size: 30px;
}

.Blog_pagination__1BghG {
  display: flex;
  justify-content: flex-end;
}

.Blog_no_blog__14aUG {
  text-align: center;
  height: 300px;
}

/* -- Mobile landscape */
@media (min-width: 480px) {
}

/* -- Tablet portrait */
@media (min-width: 768px) {
  .Blog_card_container__1rt7v {
    grid-template-columns: repeat(2, -webkit-min-content);
    grid-template-columns: repeat(2, min-content);
    grid-gap: 1.5rem;
    gap: 1.5rem;
  }
}

/* -- Tablet landscape */
@media (min-width: 992px) {
}

/* -- Laptop (HD) */
@media (min-width: 1200px) {
  .Blog_card_container__1rt7v {
    grid-template-columns: repeat(3, -webkit-min-content);
    grid-template-columns: repeat(3, min-content);
    grid-gap: 1rem;
    gap: 1rem;
  }

  .Blog_custom_center__1nTHD {
    margin: 0 !important;
  }
}

/* -- Laptop (HD) */
@media (min-width: 1600px) {
}

/* -- FullHD */
@media (min-width: 1921px) {
}

.BlogCard_card__2eRNq {
  position: relative;
  box-sizing: border-box;
  background-color: #f8fafc;
  border-radius: 0.75rem;
  max-width: 300px;
}

.BlogCard_card__2eRNq > div:first-child {
  width: 298px;
}

.BlogCard_content__2Q8Re {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 1rem;
}

.BlogCard_new_status__1w490 {
  position: absolute;
  top: -0.25rem;
  right: -0.25rem;
}

.BlogCard_content__2Q8Re > div:first-child {
  margin-bottom: auto;
}

.BlogCard_content__2Q8Re h3 {
  margin-top: unset;
  margin-bottom: 0.75rem;
  max-height: 6rem;
  overflow: hidden;
}

@supports (-webkit-line-clamp: 2) {
  .BlogCard_content__2Q8Re h3 {
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }
}

.BlogCard_card_footer__3Febh{
  text-align: center;
}

.BlogCard_card_content__71_kM{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 190px;
  padding: 0 0 1rem 0;
}

.BlogCard_lang_icon__2ASub{
  position: absolute;
  bottom: 0.6rem;
  right: 0.6rem;
  z-index:  1;
}

.BlogCard_img_con__1oXMB{
  position: relative;
}

/* -- Tablet landscape */
@media (min-width: 380px) {
  .BlogCard_card__2eRNq {
    max-width: 343px;
    height: 400px;
  }

  .BlogCard_card__2eRNq > div:first-child {
    width: unset;
  }
}

.BlogCard_button__24zYu{
  width: 91%;
  margin: 0 0.5rem;
}

.BlogThumbnail_thumbnail__KY2g- {
  position: relative;
  height: calc(12rem - 2px);
  aspect-ratio: 341 / 190;
  border: 1px solid #f1f1f1;
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
}

.BlogThumbnail_skeleton__2sRJ6 {
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
  height: 100%;
}

.BlogThumbnail_thumbnail__KY2g- img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
}

.BlogThumbnail_chip__1x6g4 {
  position: absolute;
  bottom: 0.375rem;
  left: 0.25rem;
}

.StatusChip_course_status_chip__3y6m1 {
  display: inline-block;
  background-color: rgb(227, 0, 81);
  border-radius: 3rem;
  color: white;
  font-weight: bold;
}

/* === SIZE === */

.StatusChip_small__2N0CG {
  font-size: 0.625rem;
  padding-inline: 0.375rem;
  padding-block: 0;
  line-height: 1rem;
}

.StatusChip_medium__2rY6l {
  font-size: 0.8125rem;
  padding-inline: 0.5rem;
  padding-block: 0.125rem;
  line-height: 1rem;
}

.StatusChip_large__3OhVZ {
  font-size: 0.875rem;
  padding-inline: 0.625rem;
  padding-block: 0.125rem;
  line-height: 1.25rem;
}


.PhotographerInfoPage_header_section__3hsIv{
  position: relative;
}

.PhotographerInfoPage_header_title__2-Ciw {
  font-size: 40px;
  color: black;
}

.PhotographerInfoPage_widget_container__2qwyE {
  margin: 2rem;
  display: grid;
  grid-gap: 0.5rem;
  grid-template-columns: 1fr 1fr;
}

.PhotographerInfoPage_buttons__3Ntf4 {
  display: flex;
  flex-direction: column;
}

.PhotographerInfoPage_hero__2aUzL {
  display: flex;
  position: relative;
  padding: 1rem 0 5rem 0;
}

.PhotographerInfoPage_photographer_image__pfDND {
  display: none;
}

.PhotographerInfoPage_section_padding__2xyIM {
  padding: 5rem 0;
}

.PhotographerInfoPage_problems__4P1nx {
  padding: 1rem 0 5rem;
}

.PhotographerInfoPage_container__stpYL {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.PhotographerInfoPage_help_image__1WymJ {
  border-radius: 1rem;
  margin-top: 2rem;
  width: 90vw;
}
.PhotographerInfoPage_camera_image__3Fwep {
  border-radius: 1rem;
  margin-top: 2rem;
  width: 90vw;
}

.PhotographerInfoPage_problems__4P1nx {
  padding: 1rem 0 5rem;
}

.PhotographerInfoPage_chips__YF_vy > div {
  margin: 0.2rem;
}

.PhotographerInfoPage_contact_container__pJ_pZ {
  padding-top: 1rem;
}
.PhotographerInfoPage_container__stpYL {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
}

.PhotographerInfoPage_button__35NwB {
  width: 100%;
}

.PhotographerInfoPage_logos__3Yidb {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.PhotographerInfoPage_logos__3Yidb > div {
  text-align: center;
}

.PhotographerInfoPage_logos__3Yidb > div > img {
  width: 120px;
  height: auto;
}

.PhotographerInfoPage_choices__2W4dS {
  display: flex;
  flex-direction: column;
}

.PhotographerInfoPage_more__3arPm > button {
  background-color: #f9b136;
  color: black;
  height: 40px;
  width: 100%;
}

.PhotographerInfoPage_socialIcons__2iMGP{
  display: flex;
  padding: 1rem;
  margin-right: 1rem;
  margin: 1rem auto;
  border-radius: 1rem;
  width: -webkit-max-content;
  width: max-content;
  height: -webkit-max-content;
  height: max-content;
  background-color: #000000;
  padding: 1.5rem;
  color: white;
  text-decoration: none;
}
.PhotographerInfoPage_videoWrapper__VMPYE{
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

@media (min-width: 480px) {
  .PhotographerInfoPage_widget_container__2qwyE {
    grid-gap: 1rem;
  }
  .PhotographerInfoPage_more__3arPm > button {
    width: unset;
  }
  .PhotographerInfoPage_buttons__3Ntf4 {
    flex-direction: row;
    grid-gap: 0.5rem;
    gap: 0.5rem;
  }
  .PhotographerInfoPage_button__35NwB {
    width: unset;
  }
  .PhotographerInfoPage_logos__3Yidb > div > img {
    width: 100px;
  }
  .PhotographerInfoPage_logos__3Yidb {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .PhotographerInfoPage_camera_image__3Fwep {
    height: 300px;
    width: auto;
  }
}

@media (min-width: 768px) {
  .PhotographerInfoPage_logos__3Yidb > div > img {
    width: 150px;
  }
  .PhotographerInfoPage_help_image__1WymJ{
    height: 280px;
    width: auto;
  }
  .PhotographerInfoPage_widget_container__2qwyE {
    grid-gap: 3rem;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
@media (min-width: 1100px) {
  .PhotographerInfoPage_widget_container__2qwyE {
    grid-gap: 5rem;
  }
  .PhotographerInfoPage_logos__3Yidb > div > img {
    width: 200px;
  }
  .PhotographerInfoPage_camera_image__3Fwep {
    height: 300px;
    width: auto;
  }
  .PhotographerInfoPage_section_padding__2xyIM {
    padding: 5rem 0;
  }

  .PhotographerInfoPage_container__stpYL {
    flex-direction: row;
    grid-gap: 3rem;
    gap: 3rem;
  }
  .PhotographerInfoPage_hero__2aUzL {
    display: grid;
    grid-gap: 2rem;
    gap: 2rem;
    grid-template-columns: 2fr 1fr;
    align-items: center;
    padding: 2.85rem 0;
  }
  .PhotographerInfoPage_header_title__2-Ciw {
    font-size: 45px;
  }
  .PhotographerInfoPage_photographer_image__pfDND {
    margin: auto;
    display: block;
    margin-bottom: 0rem;
    background-color: white;
    border-radius: 50%;
  }
}
/* -- Laptop (HD) */
@media (min-width: 1200px) {
  .PhotographerInfoPage_custom_center__2e0bI {
    margin: 0 !important;
  }
}

.MovingIconWidget_labelled_widget__z0nqb {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.MovingIconWidget_labelled_widget__z0nqb > div:first-child {
  display: flex;
  padding: 1rem;
  margin-right: 1rem;
  border-radius: 1rem;
  width: -webkit-max-content;
  width: max-content;
  height: -webkit-max-content;
  height: max-content;
  background-color: #000000;
  padding: 1.5rem;
  margin: 0 auto;
}

.MovingIconWidget_labelled_widget__z0nqb > div:first-child > svg {
  margin: auto;
  width: 2.25rem;
  height: 2.25rem;
}


.InstitutionInfoPage_header_section__yaLAB{
  position: relative;
}

.InstitutionInfoPage_header_title__e7SCs {
  font-size: 40px;
  color: black;
}

.InstitutionInfoPage_widget_container__30pAz {
  margin: 2rem;
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 1fr;
}

.InstitutionInfoPage_buttons__1GB0c {
  display: flex;
  flex-direction: column;
}

.InstitutionInfoPage_hero__1B3gI {
  display: flex;
  padding: 1rem 0 5rem 0;
}

.InstitutionInfoPage_institution_image__3_YeV {
  display: none;
}

.InstitutionInfoPage_section_padding__3HEYo {
  padding: 5rem 0;
}

.InstitutionInfoPage_problems__3Xaej {
  padding: 1rem 0 5rem;
}

.InstitutionInfoPage_arrow_image__1CKT2 {
  display: block;
  margin: 1rem auto;
}

.InstitutionInfoPage_container__2Gi3F {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.InstitutionInfoPage_frustrated_image__2MqKA {
  border-radius: 1rem;
  margin-top: 2rem;
  width: 90vw;
}

.InstitutionInfoPage_button__JrOAc {
  width: 100%;
}

@media (min-width: 480px) {
  .InstitutionInfoPage_buttons__1GB0c {
    flex-direction: row;
    grid-gap: 0.5rem;
    gap: 0.5rem;
  }
  .InstitutionInfoPage_button__JrOAc {
    width: unset;
  }
}

@media (min-width: 768px) {
  .InstitutionInfoPage_frustrated_image__2MqKA {
    height: 300px;
    width: auto;
  }
  .InstitutionInfoPage_widget_container__30pAz {
    grid-template-columns: 1fr 1fr;
    grid-gap: 3rem;
  }
}
@media (min-width: 1100px) {
  .InstitutionInfoPage_frustrated_image__2MqKA {
    height: 300px;
    width: auto;
  }
  .InstitutionInfoPage_section_padding__3HEYo {
    padding: 5rem 0;
  }

  .InstitutionInfoPage_container__2Gi3F {
    flex-direction: row;
    grid-gap: 3rem;
    gap: 3rem;
  }
  .InstitutionInfoPage_hero__1B3gI {
    display: grid;
    grid-gap: 2rem;
    gap: 2rem;
    grid-template-columns: 2fr 1fr;
    align-items: center;
    padding: 4.63rem 0;
  }
  .InstitutionInfoPage_header_title__e7SCs {
    font-size: 45px;
  }
  .InstitutionInfoPage_institution_image__3_YeV {
    margin: auto;
    display: block;
    margin-bottom: 0rem;
    background-color: white;
    border-radius: 50%;
    z-index: 100;
  }
  .InstitutionInfoPage_widget_container__30pAz {
    grid-gap: 5rem;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
/* -- Laptop (HD) */
@media (min-width: 1200px) {
  .InstitutionInfoPage_custom_center__g21Vm {
    margin: 0 !important;
  }
}

