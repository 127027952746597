
.header_section{
  position: relative;
}

.header_title {
  font-size: 40px;
  color: black;
}

.widget_container {
  margin: 2rem;
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 1fr;
}

.buttons {
  display: flex;
  flex-direction: column;
}

.hero {
  display: flex;
  padding: 1rem 0 5rem 0;
}

.institution_image {
  display: none;
}

.section_padding {
  padding: 5rem 0;
}

.problems {
  padding: 1rem 0 5rem;
}

.arrow_image {
  display: block;
  margin: 1rem auto;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.frustrated_image {
  border-radius: 1rem;
  margin-top: 2rem;
  width: 90vw;
}

.button {
  width: 100%;
}

@media (min-width: 480px) {
  .buttons {
    flex-direction: row;
    gap: 0.5rem;
  }
  .button {
    width: unset;
  }
}

@media (min-width: 768px) {
  .frustrated_image {
    height: 300px;
    width: auto;
  }
  .widget_container {
    grid-template-columns: 1fr 1fr;
    grid-gap: 3rem;
  }
}
@media (min-width: 1100px) {
  .frustrated_image {
    height: 300px;
    width: auto;
  }
  .section_padding {
    padding: 5rem 0;
  }

  .container {
    flex-direction: row;
    gap: 3rem;
  }
  .hero {
    display: grid;
    gap: 2rem;
    grid-template-columns: 2fr 1fr;
    align-items: center;
    padding: 4.63rem 0;
  }
  .header_title {
    font-size: 45px;
  }
  .institution_image {
    margin: auto;
    display: block;
    margin-bottom: 0rem;
    background-color: white;
    border-radius: 50%;
    z-index: 100;
  }
  .widget_container {
    grid-gap: 5rem;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
/* -- Laptop (HD) */
@media (min-width: 1200px) {
  .custom_center {
    margin: 0 !important;
  }
}
