.course_status_chip {
  display: inline-block;
  background-color: rgb(227, 0, 81);
  border-radius: 3rem;
  color: white;
  font-weight: bold;
}

/* === SIZE === */

.small {
  font-size: 0.625rem;
  padding-inline: 0.375rem;
  padding-block: 0;
  line-height: 1rem;
}

.medium {
  font-size: 0.8125rem;
  padding-inline: 0.5rem;
  padding-block: 0.125rem;
  line-height: 1rem;
}

.large {
  font-size: 0.875rem;
  padding-inline: 0.625rem;
  padding-block: 0.125rem;
  line-height: 1.25rem;
}
