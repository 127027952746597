
.header_section{
  position: relative;
}

.header_title {
  font-size: 40px;
  color: black;
}

.widget_container {
  margin: 2rem;
  display: grid;
  grid-gap: 0.5rem;
  grid-template-columns: 1fr 1fr;
}

.buttons {
  display: flex;
  flex-direction: column;
}

.hero {
  display: flex;
  position: relative;
  padding: 1rem 0 5rem 0;
}

.photographer_image {
  display: none;
}

.section_padding {
  padding: 5rem 0;
}

.problems {
  padding: 1rem 0 5rem;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.help_image {
  border-radius: 1rem;
  margin-top: 2rem;
  width: 90vw;
}
.camera_image {
  border-radius: 1rem;
  margin-top: 2rem;
  width: 90vw;
}

.problems {
  padding: 1rem 0 5rem;
}

.chips > div {
  margin: 0.2rem;
}

.contact_container {
  padding-top: 1rem;
}
.container {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
}

.button {
  width: 100%;
}

.logos {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.logos > div {
  text-align: center;
}

.logos > div > img {
  width: 120px;
  height: auto;
}

.choices {
  display: flex;
  flex-direction: column;
}

.more > button {
  background-color: #f9b136;
  color: black;
  height: 40px;
  width: 100%;
}

.socialIcons{
  display: flex;
  padding: 1rem;
  margin-right: 1rem;
  margin: 1rem auto;
  border-radius: 1rem;
  width: max-content;
  height: max-content;
  background-color: #000000;
  padding: 1.5rem;
  color: white;
  text-decoration: none;
}
.videoWrapper{
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

@media (min-width: 480px) {
  .widget_container {
    grid-gap: 1rem;
  }
  .more > button {
    width: unset;
  }
  .buttons {
    flex-direction: row;
    gap: 0.5rem;
  }
  .button {
    width: unset;
  }
  .logos > div > img {
    width: 100px;
  }
  .logos {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .camera_image {
    height: 300px;
    width: auto;
  }
}

@media (min-width: 768px) {
  .logos > div > img {
    width: 150px;
  }
  .help_image{
    height: 280px;
    width: auto;
  }
  .widget_container {
    grid-gap: 3rem;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
@media (min-width: 1100px) {
  .widget_container {
    grid-gap: 5rem;
  }
  .logos > div > img {
    width: 200px;
  }
  .camera_image {
    height: 300px;
    width: auto;
  }
  .section_padding {
    padding: 5rem 0;
  }

  .container {
    flex-direction: row;
    gap: 3rem;
  }
  .hero {
    display: grid;
    gap: 2rem;
    grid-template-columns: 2fr 1fr;
    align-items: center;
    padding: 2.85rem 0;
  }
  .header_title {
    font-size: 45px;
  }
  .photographer_image {
    margin: auto;
    display: block;
    margin-bottom: 0rem;
    background-color: white;
    border-radius: 50%;
  }
}
/* -- Laptop (HD) */
@media (min-width: 1200px) {
  .custom_center {
    margin: 0 !important;
  }
}
