.container {
  margin: 2rem 0;
}

.img_con {
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px,
    rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
  position: relative;
  margin-bottom: 32px;
  background-size: cover;
  background-repeat: no-repeat;
  height: 200px;
  width: 100%;
  background-position: center center;
}

.grid_container img {
  max-width: 100%;
}

.title {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
}

.blog_content img {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

/* -- Mobile landscape */
@media (min-width: 480px) {
  .img_con {
    height: 300px;
    text-align: center;
  }
}

/* -- Tablet portrait */
@media (min-width: 768px) {
  .img_con {
    height: 400px;
  }
}

/* -- Tablet landscape */
@media (min-width: 992px) {
  .grid_container {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 1.5rem;
    margin: 1rem 0;
  }
}

/* -- Laptop (HD) */
@media (min-width: 1200px) {
  .custom_center {
    margin: 0 !important;
  }
}

/* -- Laptop (HD) */
@media (min-width: 1600px) {
}

/* -- FullHD */
@media (min-width: 1921px) {
}
