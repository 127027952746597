.labelled_widget {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.labelled_widget > div:first-child {
  display: flex;
  padding: 1rem;
  margin-right: 1rem;
  border-radius: 1rem; 
  width: max-content;
  height: max-content;
  background-color: #000000;
  padding: 1.5rem;
  margin: 0 auto;
}

.labelled_widget > div:first-child > svg {
  margin: auto;
  width: 2.25rem;
  height: 2.25rem;
}
