.header_container{
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.header_end{
  display: flex;
  align-items: center;
  gap: 1rem;
}
  
/* -- Mobile landscape */
@media (min-width: 480px) {
}

/* -- Tablet portrait */
@media (min-width: 768px) {
}

/* -- Tablet landscape */
@media (min-width: 992px) {
}

/* -- Laptop (HD) */
@media (min-width: 1200px) {
}

/* -- Laptop (HD) */
@media (min-width: 1600px) {

}

/* -- FullHD */
@media (min-width: 1921px) {
}
  