.thumbnail {
  position: relative;
  height: calc(12rem - 2px);
  aspect-ratio: 341 / 190;
  border: 1px solid #f1f1f1;
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
}

.skeleton {
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
  height: 100%;
}

.thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
}

.chip {
  position: absolute;
  bottom: 0.375rem;
  left: 0.25rem;
}
